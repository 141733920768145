import React from "react";
import { HConfig, HFormat, HUtils, HText } from "apps/Helpers";
import { MyModal } from "apps/Components";
import HGA from "helpers/GA";
import HShopUtils from "apps/shoppingCart/helpers/Utils";
import "./styles/sc_card_product_promotion_1.css";

class SC_CardPromotion extends React.Component {
  onAddCart = (data) => {
    const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
    if (_haveProductOptions) {
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart_with_options",
        {
          data: data,
          link: "/cart",
        },
        {
          title: data.MaterialName,
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );
    } else {
      let _productUpdate = {
        Quantity: 1,
      };
      HShopUtils.addProductToCart({
        product: data,
        productUpdate: _productUpdate,
      });
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart",
        {
          textSubmit: HText.get("text_modal_btn_go_cart"),
          product: data,
          link: "/cart",
          modalType: "cart",
        },
        {
          title: HText.get("text_modal_title_add_cart"),
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );
    }

    HGA.trackingEventClick({
      action: "click_addcart_product",
      label: data.MaterialName,
    });
  };
  onAddWish = (data) => {
    let _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    if (_isInWishList) {
      HShopUtils.removeProductWishList({ product: data });
      HGA.trackingEventClick({
        action: "click_removewish_product",
        label: data.MaterialName,
      });
    } else {
      HShopUtils.addProductToWishList({ product: data });
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart",
        {
          textSubmit: HText.get("text_modal_btn_go_wishlist"),
          product: data,
          link: "/wishlist",
          modalType: "wishlist",
        },
        {
          title: HText.get("text_modal_title_add_wish"),
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );

      HGA.trackingEventClick({
        action: "click_addwish_product",
        label: data.MaterialName,
      });
    }
    this.forceUpdate();
  };
  renderProductPicture = (data) => {
    const _Slug = data.Slug;
    const _MaterialName = data.MaterialName;
    const _ImageUrlThumnail =
      data.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");

    return (
      <div className="product-picture">
        <a href={_Slug} title={_MaterialName}>
          <img src={_ImageUrlThumnail} alt={_MaterialName} />
        </a>
      </div>
    );
  };
  renderProductPrice = (data) => {
    const _SellPriceText = data.SellPriceText;
    const _SellPrice = data.SellPrice;
    const _IsCall4WebOrder = data.IsCall4WebOrder;
    const _QuantityMin = HUtils.Obj.get(data, "DiscountInfo.QuantityMin");
    const _DiscountMoney = HUtils.Obj.get(data, "DiscountInfo.DiscountMoney");
    const _PriceAfterDiscount = _SellPrice - _DiscountMoney;

    return (
      <div className="product-price">
          {_QuantityMin <= 1 && (
            <span content={_PriceAfterDiscount} className="discount-price">
              {HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}
            </span>
          )}
          <span
            className={`actual-price${_QuantityMin <= 1 ? " origin" : ""}`}
            style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
          >
            {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
          </span>
        </div>
    )
  }
  renderProductDetail = (data) => {
    const _Slug = data.Slug;
    const _MaterialName = data.MaterialName;
    return (
      <div className="product-name">
        <a href={_Slug} title={_MaterialName}>
          {_MaterialName}
        </a>
      </div>
    );
  };
  renderProductAction = (data) => {
    const _IsCall4WebOrder = data.IsCall4WebOrder;
    const _Inventory = data.Inventory;
    const _AllowOrder = _Inventory?.AllowOrder;
    const _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    const _disabledBtn = _AllowOrder === false ? true : false;
    return (
      <div className="ftr">
        {this.renderProductPrice(data)}
        <div className="actions">
          <button
            className={`add-wish${_isInWishList ? " active" : ""}`}
            onClick={() => this.onAddWish(data)}
          >
            <i className="fa fa-heart" />
          </button>
          {_IsCall4WebOrder !== true && (
            <button
              className={`add-cart${_disabledBtn ? " disabled" : ""}`}
              disabled={_disabledBtn}
              onClick={() => this.onAddCart(data)}
            >
              <i className="fa fa-shopping-basket" />
            </button>
          )}
        </div>
      </div>
    );
  };
  render() {
    const { data } = this.props;
    if (data) {
      return (
        <div className="sc_card_promotion">
          <div className="content">
            {this.renderProductDetail(data)}
            {this.renderProductPicture(data)}
            {this.renderProductAction(data)}
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_CardPromotion;
