import React from 'react'
import {MyUI} from '../../../../Components'
import './styles/sc_home.css'
import HGA from '../../../../../helpers/GA'

class HomeRecentBlog extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            blogIndex: 0
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._resize)
      }
    componentDidMount(){
        this.setWidthItem();
        window.addEventListener('resize', this._resize)
    }
    _resize=()=>{
        this.setWidthItem()
    }
    setWidthItem=()=>{
        const _blogWrapper = document.getElementsByClassName("blog-posts owl-carousel owl-theme owl-loaded");
        if(_blogWrapper && _blogWrapper.length>0){
            let _blogArray = document.getElementsByClassName("blog owl-item active");
            if(_blogArray && _blogArray.length>0){
                for(let i=0; i<_blogArray.length; i++){
                    _blogArray[i].style.width = `${_blogWrapper[0].offsetWidth}px`
                }
            }
        }
    }
    onNext=()=>{
        const {data} = this.props;
        const _length = data && data.length || 0
        let _current = this.state.blogIndex;
        let _next = _current + 1;
        if(_next == _length){
            _next = 0;
        }
        this.setState({
            blogIndex: _next
        })
    }
    onPrevious=()=>{
        const {data} = this.props;
        const _length = data && data.length || 0
        let _current = this.state.blogIndex;
        let _previous = _current - 1;
        if(_previous == -1){
            _previous = _length - 1;
        }
        this.setState({
            blogIndex: _previous
        })
    }
    render(){
        const {data, loading} = this.props;
        return (
            <div className="rich-blog-homepage">
                <div className="title">
                    <strong>Blog</strong>
                </div>
                <div className="blog-posts owl-carousel owl-theme owl-loaded">
                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: "translate3d(0px, 0px, 0px)", transition:" all 0.25s ease 0s", width: "4040px"}}>
                            {data && data.length>0 && data.map((blog,index)=>{
                                return (
                                <div key={index} className="blog owl-item active" style={{width: "384px", marginRight: "20px", display: index == this.state.blogIndex ? "block" : "none"}}>
                                    <MyUI type="sc_home_blog_post" data={blog} onClick={()=>{
                                        HGA.trackingEventClick({
                                            action:'click_item_home_blog',
                                            label: blog.Title
                                          })
                                    }}/>
                                </div>
                                ) 
                            })}
                        </div>
                    </div>
                    <div className="owl-controls">
                        <div className="owl-nav">
                            <div className="owl-prev" onClick={()=>this.onPrevious()}>prev</div>
                            <div className="owl-next" onClick={()=>this.onNext()}>next</div>
                        </div>
                        <div className="owl-dots" style={{display: "none"}}></div>
                    </div>
                </div>
                <div className="view-all">
                    <a href="/blog">View All Blog Posts</a>
                </div>      
            </div>
        )
    }
}
export default HomeRecentBlog