import React from "react";
import { MyUI } from "apps/Components";

class SC_GridView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        gridGap: 10,
        display: "grid",
      },
      settings: [
        { width: 1100, column: 3 },
        { width: 600, column: 2 },
        { width: 0, column: 1 },
      ],
      id: null,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getStyle);
  }
  componentDidMount() {
    const { settings } = this.props;
    if (settings) {
      this.setState(
        {
          settings: settings,
        },
        () => this.getStyle()
      );
    }
    this.getStyle();
    window.addEventListener("resize", this.getStyle);
  }
  getStyle = ({ otherSettings } = {}) => {
    let { style, settings } = this.state;
    const _settings = otherSettings || settings;
    const _screenWidth = window.innerWidth;
    let _gridTemplateColumns = "";
    _settings.every((v) => {
      if (_screenWidth >= v.width) {
        for (let i = 0; i < v.column; i++) {
          _gridTemplateColumns += ` calc(${100 / v.column}% - ${
            (style.gridGap * (v.column - 1)) / v.column
          }px)`;
        }
        return false;
      }
      return true;
    });
    this.setState(
      {
        style: {
          ...style,
          gridTemplateColumns: _gridTemplateColumns,
        },
        ...otherSettings && {settings: otherSettings}
      },
      () => this.forceUpdate()
    );
  };
  render() {
    const { data, cardType, config, renderComponent, fnList } = this.props;
    const { style } = this.state;
    return (
      <div style={style}>
        {renderComponent && renderComponent()}
        {!renderComponent &&
          data?.length > 0 &&
          data.map((v, i) => {
            return (
              <MyUI
                key={i}
                type={cardType}
                data={v}
                config={config}
                fnList={fnList}
              />
            );
          })}
      </div>
    );
  }
}
export default SC_GridView;
